<template>
  <h1>
    Page Not Found
  </h1>
</template>

<script>
export default {
  mounted() {
    // enable black background
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('bg-primary');
  },
  unmounted() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-primary');
  },
};
</script>
